import { Skeleton, Stack } from '@mui/material'
import React, { FC } from 'react'

export const HeaderSkeleton: FC = () => {
  return (
    <Stack
      direction={'row'}
      spacing={2}
      marginLeft={'34px'}
      width={'100%'}
      alignItems={'center'}
    >
      <Skeleton
        variant={'rectangular'}
        sx={{
          backgroundColor: 'grey.400',
          width: '90px',
          height: '20px',
          borderRadius: '15px',
        }}
      />
      <Skeleton
        variant={'rectangular'}
        sx={{
          backgroundColor: 'grey.400',
          width: '90px',
          height: '20px',
          borderRadius: '15px',
        }}
      />
      <Skeleton
        variant={'rectangular'}
        sx={{
          backgroundColor: 'grey.400',
          width: '90px',
          height: '20px',
          borderRadius: '15px',
        }}
      />
      <Skeleton
        variant={'rectangular'}
        sx={{
          backgroundColor: 'grey.400',
          width: '90px',
          height: '20px',
          borderRadius: '15px',
        }}
      />
      <Skeleton
        variant={'rectangular'}
        sx={{
          backgroundColor: 'grey.400',
          width: '88px',
          height: '40px',
          marginLeft: 'auto !important',
          borderRadius: '20px',
        }}
      />
    </Stack>
  )
}
