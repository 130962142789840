import {
  AppBar,
  Avatar,
  Box,
  Button,
  NoSsr,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { ButtonCustom } from 'components/Button'
import { MenuCustom } from 'components/MenuCustom'
import { NavButton } from 'components/NavButton'
import { HeaderSkeleton } from 'components/Skeleton/header'
import { TooltipForEllipsis } from 'components/TooltipForEllipsis'
import {
  useLogout,
  useNotificationsContext,
  useScrollDirection,
  useUserContext,
} from 'hooks'
import { LogoIcon, MenuIcon } from 'icons'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, MouseEvent, useState } from 'react'
import { UserRoles } from 'types/enums'
import { TokenStorage } from 'utils/auth'
import { getCurrentUrl } from 'utils/url'
import { getUserHomeUrlByRole } from 'utils/user'

const tokenStorage = TokenStorage.getInstance()

export const Header: FC = () => {
  const router = useRouter()
  const { unreadNotificationsTotal } = useNotificationsContext()
  const { user, fetchUser } = useUserContext()
  const isEnabled = user?.isEnabled
  const isAuthenticated = Boolean(user)
  const isSeller = Boolean(
    user?.roles.some(role => role.code === UserRoles.Seller)
  )
  const isBuyer = Boolean(
    user?.roles.some(role => role.code === UserRoles.Buyer)
  )
  const isAdmin = Boolean(
    user?.roles.some(role => role.code === UserRoles.Admin)
  )

  const userFirstName = user?.firstName ?? ''
  const userLastName = user?.lastName ?? ''
  const userNameFirstLetter = userFirstName && userFirstName[0].toUpperCase()
  const userEmail = user?.email ?? ''

  const logout = useLogout()
  const scrollDirection = useScrollDirection()

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const logoutFromUserAccount = async () => {
    tokenStorage.removeLoggedInAsUserAccessToken()
    tokenStorage.removeLoggedInAsUserRefreshToken()

    try {
      await fetchUser()

      void router.push(getUserHomeUrlByRole(UserRoles.Admin))
    } catch (error) {
      console.error(error)
    }
  }

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const isLoggedInAsUser = tokenStorage.getLoggedInAsUserAccessToken()
  const accessToken = tokenStorage.getAccessToken()
  const isUserInfoLoading = accessToken && !user

  return (
    <NoSsr>
      <AppBar
        color='secondary'
        position='sticky'
        className={`${scrollDirection === 'down' ? 'hide' : ''}`}
        sx={{
          boxShadow: 'none',
          top: 0,
          zIndex: 101,
          transition: 'top 0.5s',
          minWidth: '768px',
          '&.hide': {
            top: '-72px',
          },
        }}
      >
        <Box
          sx={{
            p: {
              xs: '16px',
              md: '16px 24px',
              lg: '16px 40px',
            },
          }}
        >
          <Toolbar
            disableGutters
            variant={'dense'}
            sx={{ alignItems: 'flex-start', minHeight: 'auto' }}
          >
            <Link
              passHref
              href={getUserHomeUrlByRole(user?.roles[0].code).pathname}
            >
              <Box
                sx={{
                  width: {
                    xs: '100px',
                    md: '141px',
                  },
                  height: '28px',
                }}
              >
                <LogoIcon
                  width={'100%'}
                  height={'100%'}
                  viewBox='0 0 141 28'
                  color='white'
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Link>

            {isUserInfoLoading ? (
              <HeaderSkeleton />
            ) : (
              <>
                <Box
                  sx={{
                    flexGrow: 1,
                    ml: {
                      xs: '4px',
                      md: '34px',
                    },
                    pt: '4px',
                  }}
                >
                  <Stack direction={'row'} spacing={1}>
                    {!isAuthenticated && (
                      <>
                        <NavButton isExternal to='https://about.bidsheet.io/'>
                          Buyers
                        </NavButton>
                        <NavButton
                          isExternal
                          to='https://about.bidsheet.io/sellers'
                        >
                          Sellers
                        </NavButton>
                        <NavButton to='/auctions'>Auctions</NavButton>
                        <NavButton isExternal to='https://help.bidsheet.io/'>
                          Support
                        </NavButton>
                      </>
                    )}

                    {isAdmin && (
                      <>
                        <NavButton to='/admin/users'>Users</NavButton>
                        <NavButton to='/admin/auctions'>Auctions</NavButton>
                        <NavButton to='/admin/orders'>Orders</NavButton>
                        <NavButton to='/admin/categories'>Categories</NavButton>
                      </>
                    )}

                    {isSeller && (
                      <>
                        <NavButton to='/dashboard'>Dashboard</NavButton>
                        <NavButton to='/auctions/my'>My auctions</NavButton>
                        <NavButton to='/orders'>Orders</NavButton>
                        <NavButton to='/lots'>Inventory</NavButton>
                        <NavButton isExternal to='https://help.bidsheet.io/'>
                          Support
                        </NavButton>
                      </>
                    )}

                    {isBuyer && (
                      <>
                        <NavButton to='/auctions'>Auctions</NavButton>
                        <NavButton to='/orders'>Orders</NavButton>
                        <NavButton isExternal to='https://help.bidsheet.io/'>
                          Support
                        </NavButton>
                        <NavButton to='/auctions/current'>My bids</NavButton>
                        <NavButton to='/auctions/watchlist'>
                          Watchlist
                        </NavButton>
                      </>
                    )}
                  </Stack>
                </Box>
                <Box display='flex' alignItems='center' sx={{ flexGrow: 0 }}>
                  {!isAuthenticated && (
                    <>
                      <NavButton
                        to={`/account/login?redirectTo=${getCurrentUrl().href}`}
                      >
                        Log in
                      </NavButton>
                      <Link passHref href='/account/register'>
                        <ButtonCustom
                          height={'40px'}
                          sx={{
                            fontSize: {
                              xs: '13px',
                              lg: '16px',
                            },
                            p: {
                              xs: '6px 10px',
                              md: '6px 12px',
                              lg: '6px 16px',
                            },
                            ml: {
                              xs: '4px',
                              md: '6px',
                              lg: '12px',
                            },
                          }}
                        >
                          <span>Create an account</span>
                        </ButtonCustom>
                      </Link>
                    </>
                  )}

                  {isSeller && router.pathname !== '/auctions/create' && (
                    <Link passHref href='/auctions/create'>
                      <ButtonCustom
                        height={'40px'}
                        disabled={!isEnabled}
                        sx={{
                          fontSize: {
                            xs: '13px',
                            lg: '16px',
                          },
                          p: {
                            xs: '6px 10px',
                            md: '6px 12px',
                            lg: '6px 16px',
                          },
                          ml: {
                            xs: '4px',
                            md: '6px',
                            lg: '12px',
                          },
                        }}
                      >
                        <span>Create auction</span>
                      </ButtonCustom>
                    </Link>
                  )}

                  {isAuthenticated && (
                    <>
                      <Tooltip title='Open settings'>
                        <Button
                          sx={{
                            bgcolor: 'grey.500',
                            p: {
                              xs: '4px 4px 4px 10px',
                              lg: '4px 4px 4px 15px',
                            },
                            minWidth: {
                              xs: '72px',
                              lg: '88px',
                            },
                            ml: {
                              xs: '4px',
                              md: '12px',
                            },
                            ':hover': {
                              bgcolor: 'grey.500',
                            },
                          }}
                          onClick={handleOpenUserMenu}
                        >
                          <MenuIcon
                            width={24}
                            height={24}
                            color='rgba(255, 255, 255, 1)'
                          />
                          <Avatar
                            sx={{
                              width: 32,
                              height: 32,
                              ml: {
                                xs: '6px',
                                lg: '12px',
                              },
                              backgroundColor: isLoggedInAsUser
                                ? 'rgba(255,184,0, 1)'
                                : '#009970',
                              color: 'white',
                              fontSize: '17px',
                            }}
                            alt=''
                          >
                            {userNameFirstLetter}
                          </Avatar>
                        </Button>
                      </Tooltip>
                      <MenuCustom
                        Header={
                          <Box>
                            <TooltipForEllipsis
                              title={`${userFirstName} ${userLastName}`}
                              sx={{
                                fontWeight: 600,
                                maxWidth: '184px',
                              }}
                            />
                            <Typography
                              fontSize={14}
                              color='rgba(34, 34, 34, 0.48)'
                            >
                              {userEmail}
                            </Typography>
                          </Box>
                        }
                        Footer={
                          <Typography fontSize={16} onClick={logout}>
                            {isLoggedInAsUser
                              ? 'Log out from all accounts'
                              : 'Log out'}
                          </Typography>
                        }
                        onClose={handleCloseUserMenu}
                        items={[
                          !isAdmin && (
                            <Link passHref key={1} href='/notifications'>
                              <Box
                                alignItems='center'
                                display='flex'
                                justifyContent='space-between'
                              >
                                <Typography fontSize={16}>
                                  Notifications
                                </Typography>
                                {Boolean(unreadNotificationsTotal) && (
                                  <Box
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    sx={{
                                      minWidth: '18px',
                                      px: '4px',
                                      height: '18px',
                                      borderRadius: '9px',
                                      bgcolor: 'primary.main',
                                    }}
                                  >
                                    <Typography
                                      fontSize={12}
                                      fontWeight={700}
                                      color='#FFFFFF'
                                    >
                                      {unreadNotificationsTotal}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Link>
                          ),
                          isSeller && (
                            <Link passHref key={2} href='/payouts'>
                              <Typography fontSize={16}>Payouts</Typography>
                            </Link>
                          ),
                          <Link passHref key={3} href='/settings#1'>
                            <Typography fontSize={16}>Settings</Typography>
                          </Link>,
                          isLoggedInAsUser && (
                            <Typography
                              sx={{ backgroundColor: 'rgba(255,184,0, 1)' }}
                              fontSize={16}
                              onClick={logoutFromUserAccount}
                            >
                              Log out from user&apos;s account
                            </Typography>
                          ),
                        ]}
                        open={Boolean(anchorElUser)}
                        anchorEl={anchorElUser}
                      />
                    </>
                  )}
                </Box>
              </>
            )}
          </Toolbar>
        </Box>
      </AppBar>
    </NoSsr>
  )
}
