import { Box, Divider, Menu, MenuItem } from '@mui/material'
import React, { FC } from 'react'

import { Props } from './MenuCustom.types'

const wrapperStyles = {
  mt: '45px',
  '& .MuiPaper-root': {
    minWidth: '232px',
    borderRadius: '12px',
    boxShadow:
      '0px 0px 2px rgba(34, 34, 34, 0.1), 0px 8px 18px rgba(34, 34, 34, 0.1)',
  },
  '& .MuiMenuItem-root': {
    padding: '0',
  },
  '& .MuiMenuItem-root > div, .MuiMenuItem-root > p ': {
    padding: '12px 24px',
    width: '100%',
  },
}

export const MenuCustom: FC<Props> = ({
  anchorEl,
  onClose,
  Footer,
  Header,
  items,
  ...props
}) => {
  return (
    <Menu
      sx={wrapperStyles}
      id='menu-appbar'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
      {...props}
    >
      {Header && (
        <div>
          <MenuItem
            sx={{
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '.MuiTouchRipple-root': {
                display: 'none',
              },
            }}
          >
            <Box display='flex' flexDirection='column'>
              {Header}
            </Box>
          </MenuItem>
          <Divider />
        </div>
      )}
      {items
        ?.filter(item => Boolean(item))
        ?.map((Item, idx) => (
          <MenuItem key={idx}>{Item}</MenuItem>
        ))}
      {Footer && (
        <div>
          <Divider />
          <MenuItem>{Footer}</MenuItem>
        </div>
      )}
    </Menu>
  )
}
