import { Button, Link as MuiLink } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'

import { Props } from './NavButton.types'

export const NavButton: FC<Props> = ({ to, children, isExternal }) => {
  const router = useRouter()
  const isLinkActive = router.pathname === to
  return (
    <Button
      className={isLinkActive ? 'active' : ''}
      sx={{
        display: 'block',
        textTransform: 'none',
        flexShrink: 0,
        p: {
          xs: '2px 8px',
          lg: '4px 12px',
        },
        '> a': {
          color: '#FFFFFF',
          textDecoration: 'none',
          fontSize: {
            xs: '13px',
            lg: '16px',
          },
          fontWeight: 600,
          lineHeight: {
            xs: '16px',
            lg: '24px',
          },
          py: '4px',
        },
        ':hover, &.active': {
          backgroundColor: 'grey.400',
        },
        ':active': {
          backgroundColor: 'grey.500',
        },
      }}
    >
      {isExternal ? (
        <MuiLink href={to} rel='noopener noreferrer' target='_blank'>
          {children}
        </MuiLink>
      ) : (
        <Link href={to}>{children}</Link>
      )}
    </Button>
  )
}
