import { Box } from '@mui/material'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import React, { FC } from 'react'

export const MainLayout: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <>
      <Header />
      <Box
        sx={{
          maxWidth: '1440px',
          minHeight: `calc(100vh - 72px * 2)`,
          minWidth: '768px',
          p: {
            xs: '40px 24px 80px',
            lg: '40px 40px 120px',
          },
          m: '0 auto',
        }}
      >
        {children}
      </Box>
      <Footer />
    </>
  )
}
