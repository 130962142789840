import { Box, Grid, Link as MuiLink, Stack, Typography } from '@mui/material'
import { useUserContext } from 'hooks'
import Link from 'next/link'
import React from 'react'
import { UserRoles } from 'types/enums'
export const Footer = () => {
  const { user } = useUserContext()
  const isAdmin = Boolean(
    user?.roles.some(role => role.code === UserRoles.Admin)
  )
  return (
    <Box
      sx={{
        bgcolor: 'rgba(34, 34, 34, 0.04)',
        p: '24px 40px',
        minWidth: '768px',
      }}
    >
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Typography color={'grey.600'} variant={'body2'}>
          © 2022 Bidsheet
        </Typography>
        {!isAdmin && (
          <Stack direction={'row'} spacing={'32px'} sx={{ ml: 'auto' }}>
            <MuiLink
              href={'/terms-of-use'}
              sx={{
                textDecoration: 'none',
              }}
            >
              <Typography
                color={'grey.600'}
                sx={{ cursor: 'pointer' }}
                variant={'body2'}
              >
                Terms of Service
              </Typography>
            </MuiLink>
            <MuiLink
              href={'/privacy-policy'}
              sx={{
                textDecoration: 'none',
              }}
            >
              <Typography
                color={'grey.600'}
                sx={{ cursor: 'pointer' }}
                variant={'body2'}
              >
                Privacy Policy
              </Typography>
            </MuiLink>
            <MuiLink
              href={'/cookie-policy'}
              sx={{
                textDecoration: 'none',
              }}
            >
              <Typography
                color={'grey.600'}
                sx={{ cursor: 'pointer' }}
                variant={'body2'}
              >
                Cookie policy
              </Typography>
            </MuiLink>
            <Link href={'mailto:support@bidsheet.io'}>
              <Typography
                color={'grey.600'}
                sx={{ cursor: 'pointer' }}
                variant={'body2'}
              >
                Contact Us
              </Typography>
            </Link>
            <MuiLink
              rel='noopener noreferrer'
              target='_blank'
              href={'https://help.bidsheet.io/'}
              sx={{
                textDecoration: 'none',
              }}
            >
              <Typography
                color={'grey.600'}
                sx={{ cursor: 'pointer' }}
                variant={'body2'}
              >
                Help
              </Typography>
            </MuiLink>
          </Stack>
        )}
      </Grid>
    </Box>
  )
}
